import { createUseStyles } from 'react-jss'
export const useStyles = createUseStyles({
  heyLoader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    right: '0',
    width: '100%',
    height: '100%',
    zIndex: '999',
    borderRadius: '10px',
    background: (props) => (props ? props.__loader_bg : 'rgba(0, 0, 0, 0.15)'),
    '& .hlCricle': {
      width: '60px',
      height: '60px',
      flex: '0 0 60px',
      borderRadius: '50%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      background: (props) =>
        props ? props.__loaderI_bg : 'rgba(255, 255, 255, 0.55)',
      border: (props) =>
        props
          ? '3px solid ' + props.__dar_ligFo + ' !important'
          : '3px solid var(--light) !important',
      boxShadow: '0 0 20px rgba(159, 106, 158, 0.5)',
      transform: 'translate(-50%, -50%)',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '-3px',
        left: '-3px',
        width: '60px',
        height: '60px',
        flex: '0 0 60px',
        border: '3px solid transparent',
        borderTop: '3px solid var(--primary)',
        borderRight: '3px solid var(--primary)',
        borderRadius: '50%',
        animation: 'animateHeyLC 2s linear infinite',
      },
      '& > img': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      },
      '& > span': {
        display: 'block',
        position: 'absolute',
        top: 'calc(50% - 2px)',
        left: '50%',
        width: '50%',
        height: '4px',
        background: 'transparent',
        transformOrigin: 'left',
        animation: 'animateHeyL 2s linear infinite',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: '14px',
          height: '14px',
          borderRadius: '50%',
          background: 'var(--primary)',
          top: '-6px',
          right: '-8px',
          boxShadow: '0 0 20px var(--primary)',
        },
      },
    },
  },
  smallLoader: {
    '& svg': {
      width: '20px',
      height: '20px',
      '& > path': {
        fill: (props) => (props ? props.__the_ligFi : 'var(--theme)'),
      },
    },
  },
  hlOpen: {
    overflow: 'hidden',
  },
})
