import React from 'react'
const withHostCheck = (WrappedComponent) => {
  const validHosts = ['maketimecount.today', 'localhost', '192.168.1.33']
  // const validHosts = ['maketimecount.today']
  const isHostAllowed = () => {
    const { hostname } = window.location
    return validHosts.some((host) => new RegExp(host).test(hostname))
  }
  return (props) => {
    if (isHostAllowed()) {
      return <WrappedComponent {...props} />
    } else {
      return <div>Access Denied: Invalid Host</div> // Or redirect to an error page
    }
  }
}
export default withHostCheck
