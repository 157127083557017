import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import monoDarkSvg from 'assets/images/monoDark.svg'
import classNames from 'classnames'
import { withTheme } from 'react-jss'
import { useStyles } from './style'
import { getStorageData } from 'utils/helper'

const LoadingIconComponent = ({
  heyLoader,
  theme,
  extraClass,
  style,
  smallLoader,
  iconUrl,
}) => {
  const classes = useStyles(theme)
  const portalBrandingData = sessionStorage.getItem('portal_branding')
  const portalBranding = portalBrandingData
    ? JSON.parse(portalBrandingData)
    : {}
  const userTheme = getStorageData('userTheme')

  return (
    <div
      className={classNames(
        heyLoader && classes.heyLoader,
        extraClass,
        smallLoader && classes.smallLoader,
        'heyLoaderCommon',
      )}
      style={style}
    >
      {heyLoader ? (
        <div className="hlCricle">
          <img
            width={30}
            height={27}
            alt="MTC Monogram"
            src={`${
              iconUrl
                ? iconUrl
                : portalBranding &&
                    ((portalBranding.loader_light_logo &&
                      userTheme === 'light') ||
                      (portalBranding.loader_dark_logo && userTheme === 'dark'))
                  ? userTheme === 'light'
                    ? portalBranding.loader_light_logo
                    : portalBranding.loader_dark_logo
                  : monoDarkSvg
            }`}
          />
          <span></span>
        </div>
      ) : (
        <LoadingOutlined />
      )}
    </div>
  )
}
export default withTheme(LoadingIconComponent)
