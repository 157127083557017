import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RollbarErrorTracking from 'utils/v2/rollbar.js'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorInfo: '',
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, errorInfo: error.message || '' }
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to Rollbar
    RollbarErrorTracking.logErrorInRollbar(error)
  }

  render() {
    if (this.state.hasError) {
      return (
        <p>
          Something went wrong!
          <br />
          {this.state.errorInfo}
        </p>
      )
    }
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
}

export default ErrorBoundary
