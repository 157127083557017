import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root'))

if (window !== window.top) {
  // This page is being loaded in an iframe, take appropriate action
  // For example, you can redirect the user to a safe page or display an error message
  alert(
    'For security purposes, our project cannot be used within an iframe. Thank you for your understanding.'
  )
} else {
  // Render your React app as usual
  root.render(<App />)
}

// root.render(
//     <App />
// );

reportWebVitals()
